
.StudentHome {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    padding: 30px;

    .home-title {
        font-size: 14px;
        color: #332D42;
        overflow: hidden;

        .schoolname,
        .roomname {
            float: left;
        }

        .schoolname {
            margin-right: 100px;
        }
    }

    .home-dataContent {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;

        .dataContent-item {
            margin: 0 33px 20px 0;
            padding: 12px 16px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            border-radius: 8px;
            min-width: calc(25% - 25px);

            &:last-child {
                background: linear-gradient(270deg, #4C9EFF 0%, #3885FF 100%);
                margin: 0 0 20px 0;
            }

            &:first-child {
                background: linear-gradient(270deg, #469BFF 0%, #3380FF 100%);
            }

            &:nth-child(2) {
                background: linear-gradient(270deg, #7780FF 0%, #5E65FF 100%);
            }

            &:nth-child(3) {
                background: linear-gradient(270deg, #10C0FF 0%, #0BA8FF 100%);
            }

            .item_left {
                display: flex;
                flex: 1;

                .icon {
                    width: 40px;
                    height: 40px;
                    margin-right: 20px;
                }

                .data {
                    padding-top: 10px;
                    color: #FFFFFF;
                    width: 120px;

                    .contentText {
                        font-size: 14px;
                        white-space: nowrap;
                    }

                    .contentNum {
                        font-size: 40px;
                        height: 56px;
                        padding-top: 8px;
                    }

                    .contentMoon {
                        font-size: 12px;
                        white-space: nowrap;
                    }
                }
            }

            .bigicon {
                opacity: 0.5;
                margin-left: 10px;
            }
        }
    }

    .homeTab {
        :deep(.el-tabs__header) {
            border-bottom: none;
            border-radius: 4px;

            .el-tabs__item {
                border-color: #2821FC;
                border-top: 1px solid #2821FC;

                &:first-child {
                    border-left: 1px solid #2821FC;
                    border-radius: 4px 0 0 4px;
                }

                &:last-child {
                    border-right: 1px solid #2821FC;
                    border-radius: 0 4px 4px 0;
                }

                &.is-active {
                    background-color: #2821FC;
                    color: #FFFFFF;
                }
            }
        }

        // ::v-deep .el-tabs__header {
        //     border-bottom: none;
        // }
    }
}